<template>
    <h1>
        Řídící panel
    </h1>
    <p>
        Celkový přehled systému
    </p>
    <v-divider class="mb-4"></v-divider>
    <v-card outlined>
        <v-card-title>
            <v-icon icon="mdi-information"></v-icon>
            <span class="ml-2">Celkový přehled</span>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="4">
                    <v-card outlined>
                        <v-card-title>
                            <v-icon icon="mdi-account"></v-icon>
                            <span class="ml-2">Počet zákazníků</span>
                        </v-card-title>
                        <v-card-text>
                            <h2>{{ overview.customersCount }}</h2>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card outlined>
                        <v-card-title>
                            <v-icon icon="mdi-car"></v-icon>
                            <span class="ml-2">Počet kurýrů</span>
                        </v-card-title>
                        <v-card-text>
                            <h2>{{ overview.couriersCount }}</h2>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card outlined>
                        <v-card-title>
                            <v-icon icon="mdi-store"></v-icon>
                            <span class="ml-2">Počet restaurací</span>
                        </v-card-title>
                        <v-card-text>
                            <h2>{{ overview.restaurantsCount }}</h2>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card outlined>
                        <v-card-title>
                            <v-icon icon="mdi-food"></v-icon>
                            <span class="ml-2">Počet jídel</span>
                        </v-card-title>
                        <v-card-text>
                            <h2>{{ overview.mealsCount }}</h2>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card outlined>
                        <v-card-title>
                            <v-icon icon="mdi-cart"></v-icon>
                            <span class="ml-2">Počet objednávek</span>
                        </v-card-title>
                        <v-card-text>
                            <h2>{{ overview.ordersCount }}</h2>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
    <v-divider class="mb-4"></v-divider>
    <!-- Peníze -->
    <v-card outlined>
        <v-card-title>
            <v-icon icon="mdi-cash"></v-icon>
            <span class="ml-2">Peníze</span>
        </v-card-title>
        <v-card-text>
            <v-row>

            </v-row>
        </v-card-text>
    </v-card>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useAdmin } from "../../composables/useAdmin";
import Swal from "sweetalert2";

const { getOverview } = useAdmin();

const overview = ref({
    couriersCount: 0,
    customersCount: 0,
    mealsCount: 0,
    ordersCount: 0,
    restaurantsCount: 0
});

const fetchOverview = async () => {
    try {
        const response = await getOverview();
        overview.value = response.data;
    } catch (error) {
        Swal.fire({
            title: "Chyba",
            text: "Nepodařilo se načíst přehled systému",
            icon: "error",
        });
    }
};

onMounted(() => {
    fetchOverview();
});

</script>

<style scoped></style>