import axios from "axios";
import { getCookie } from "../utils/susenka";

const API_URL = "https://localhost:44390";

const api = axios.create({
    baseURL: API_URL,
    withCredentials: true, // Povolení cookies pro všechny požadavky, včetně HTTPOnly
    headers: {
        "Content-Type": "application/json",
    },

    validateStatus: function (status) {
        return status < 500 && status !== 401; // Povolení zpracování chyb na úrovni aplikace (jiné chyby to nedropne)
    }
});

// Interceptor na načtení tokenu z cookies a jeho přidání do hlavičky požadavku
api.interceptors.request.use((config) => {
    const token = getCookie("jwt");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// Interceptor na zachycení chyby 401 (Unauthorized) a pokus o obnovení tokenu
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const client = axios.create({
                    baseURL: API_URL,
                    withCredentials: true,
                    headers: { Authorization: `Bearer ${token}` },
                });
                const response = await client.post("/auth/refresh-token");
                token = response.data.token;
                originalRequest.headers.Authorization = `Bearer ${token}`;
                return api(originalRequest);
            } catch (refreshError) {
                return Promise.reject(refreshError);
            }
        }
        
        return Promise.reject(error);
    }
);

export function useApi() {
    return {
        get: (url, config) => api.get(url, config),
        post: (url, data, config) => api.post(url, data, config),
        put: (url, data, config) => api.put(url, data, config),
        delete: (url, config) => api.delete(url, config),
    };
}
