import { useApi } from "./useApi";

const api = useApi();

export function useManager() {
    return {
        // Vytvoření nové restaurace
        createRestaurant: (restaurantData) =>
            api.post('/manager/restaurant', restaurantData),

        // Úprava restaurace
        editRestaurant: (restaurantId, restaurantData) =>
            api.put(`/manager/restaurant/${restaurantId}`, restaurantData),

        // Načtení restaurace manažera
        getRestaurant: () =>
            api.get('/manager/restaurant'),
    };
}
