<template>
    <div>
        <h1>Správa restaurací</h1>
        <v-divider class="mb-4"></v-divider>
        <!-- Formulář pro přidání restauratéra -->
        <v-card class="mb-4" outlined>
            <v-card-title>
                <v-icon icon="mdi-account-plus"></v-icon>
                <span class="ml-2">Přidání nového restauratéra do systému</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="formValid">
                    <v-text-field label="Email manažera" v-model="managerEmail" :rules="[rules.required, rules.email]"
                        required />
                    <v-text-field label="Jméno manažera" v-model="managerName" :rules="[rules.required]" required />
                    <v-text-field label="Email zaměstnance" v-model="employeeEmail" :rules="[rules.optionalEmail]" />
                    <v-btn :loading="isAddingRestaurant" :disabled="!formValid" @click="createRestaurant"
                        color="primary">
                        Přidat
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useAdmin } from "../../composables/useAdmin";
import Swal from "sweetalert2";

const { createRestaurantAccount, getRestaurantSales } = useAdmin();

// Formulářová data
const managerEmail = ref("");
const managerName = ref("");
const employeeEmail = ref("");
const formValid = ref(false);
const restaurantId = ref("");
const dateFrom = ref(null);
const dateTo = ref(null);
const form = ref(null);

// Stav načítání
const isAddingRestaurant = ref(false);
const isFetchingSales = ref(false);

// Data pro prodeje
const sales = ref([]);

// Pravidla pro validaci
const rules = {
    required: (v) => !!v || "Toto pole je povinné",
    email: (v) => /.+@.+\..+/.test(v) || "Zadejte platnou emailovou adresu",
    optionalEmail: (v) => !v || /.+@.+\..+/.test(v) || "Zadejte platnou emailovou adresu",
};

// Funkce pro vytvoření restaurace
const createRestaurant = async () => {
    if (!formValid.value) return;

    isAddingRestaurant.value = true;
    try {
        const response = await createRestaurantAccount({
            ManagerEmail: managerEmail.value,
            ManagerName: managerName.value,
            EmployeeEmail: employeeEmail.value,
        });
        Swal.fire("Restaurace přidána", `API: ${response.data}`, "success");
        resetForm();
    } catch (error) {
        console.error(error);
        Swal.fire("Chyba", "Nepodařilo se přidat restauraci.", "error");
    } finally {
        isAddingRestaurant.value = false;
    }
};

// Funkce pro načtení prodejů
const fetchSales = async () => {
    if (!restaurantId.value) return;

    isFetchingSales.value = true;
    try {
        const response = await getRestaurantSales(
            restaurantId.value,
            dateFrom.value,
            dateTo.value
        );
        sales.value = response.data;
    } catch (error) {
        console.error(error);
        Swal.fire("Chyba", "Nepodařilo se načíst prodeje.", "error");
    } finally {
        isFetchingSales.value = false;
    }
};

// Resetování formuláře
const resetForm = () => {
    managerEmail.value = "";
    managerName.value = "";
    employeeEmail.value = "";
    formValid.value = false;
    form.value?.resetValidation();
};
</script>

<style scoped>
.mb-4 {
    margin-bottom: 16px;
}
</style>
