<template>
    <v-container class="container-login">
        <v-row>
            <v-col cols="12" sm="6" offset-sm="3">
                <v-card>
                    <v-card-title>
                        <h1>Přihlášení do systému</h1>
                    </v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="handleLogin">
                            <v-text-field v-model="email" label="E-mail" type="email" required
                                autocomplete="email"></v-text-field>
                            <v-text-field v-model="password" label="Heslo" type="password" required
                                autocomplete="current-password"></v-text-field>
                            <v-btn type="submit" color="primary">Přihlásit se</v-btn>
                            <p v-if="error" class="error">{{ error }}</p>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { ref } from "vue";
import { useAuth } from "../../composables/useAuth";
import { useRouter } from "vue-router";
import { getCookie } from "../../utils/susenka";
import { decodeToken } from "../../utils/jwt";

export default {
    setup() {
        const email = ref("");
        const password = ref("");
        const error = ref(null);
        const { login } = useAuth();
        const router = useRouter();

        const handleLogin = async () => {
            try {
                error.value = null;

                // Call na api, kde se provede login a ulozi se token do cookies
                await login({ email: email.value, password: password.value });

                // Zisk tokenu z cookies
                const token = getCookie("jwt");

                // Dekodovani tokenu
                const decodedToken = decodeToken(token);

                // Make sure decodedToken is valid
                if (decodedToken && decodedToken.role) {
                    const userRole = decodedToken.role;

                    // Redirect based on user role
                    if (userRole === "Admin") {
                        await router.push({ name: "AdminDashboard" });
                    } else if (userRole === "RestaurantManager") {
                        await router.push({ name: "ManagerDashboard" });
                    } else {
                        console.warn("Unauthorized role:", userRole);
                        await router.push({ name: "Login" }); // Or another page for unauthorized users
                    }
                } else {
                    throw new Error("Dev: Invalid token");
                }
            } catch (e) {
                console.error(e);
                error.value = "Přihlášení se nezdařilo. Zkontrolujte e-mail a heslo.";
            }
        };

        return { email, password, error, handleLogin };
    },
};
</script>

<style scoped>
.container-login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-width: 80vw;
}

h1 {
    font-weight: 300;
}

.error {
    color: red;
    font-size: 0.8rem;
    margin-top: 0.5rem;
}
</style>