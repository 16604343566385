import { ref } from "vue";
import { jwtDecode } from "jwt-decode";
import { useApi } from "./useApi";
import { setCookie, getCookie, deleteCookie } from "../utils/susenka";

export function useAuth() {
  const { post, get } = useApi();

  // === Token handling ===

  // Login function
  const login = async (credentials) => {
    const response = await post("/auth/login", credentials);

    console.log("Login response", response);
  };

  // Logout function
  const logout = async () => {
    await post("/auth/logout");

    // todo pripadne vynulovat usera a nejaky globalni state
  };

  // === Password change ===
  const changePassword = async (passwords) => {
    await post("/auth/change-password", passwords);
  };

  // === User data ===
  const getMyData = async () => {
    const response = await get("/auth/user");
    return response.data;
  };

  return { login, logout, changePassword, getMyData };
}
