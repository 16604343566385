import { jwtDecode } from "jwt-decode";

// Funkce pro dekódování JWT tokenu (token musí být string)
export function decodeToken(token) {
  try {
    const decoded = jwtDecode(token); // Dekóduje token
    return decoded;
  } catch (error) {
    console.error('Invalid token:', error);
    return null; // Pokud je token neplatný, vrátí null
  }
}

export function getRoleFromToken(token) {
  const decodedToken = decodeToken(token);
  if (!decodedToken) return null; // Pokud není validní token, vrátíme null
  
  return decodedToken.role; // Vrátíme roli z dekódovaného tokenu
}