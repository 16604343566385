// Funkce pro získání hodnoty cookie podle názvu
export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
}

// Funkce pro nastavení cookie
export function setCookie(name, value, exp) {
  const expires = new Date(exp); // Exp is in milliseconds
  document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
}

// Funkce pro smazání cookie
export function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
}

// Funkce pro nastavení cookie s možností více parametrů
export function setCookieWithOptions(name, value, options = {}) {
  let cookieString = `${name}=${value}`;

  // Expirace
  if (options.expires) {
    cookieString += `; expires=${options.expires.toUTCString()}`;
  }

  // Cesta (path)
  if (options.path) {
    cookieString += `; path=${options.path}`;
  }

  // Doména (domain)
  if (options.domain) {
    cookieString += `; domain=${options.domain}`;
  }

  // Secure a HttpOnly
  if (options.secure) {
    cookieString += `; secure`;
  }

  if (options.httpOnly) {
    cookieString += `; HttpOnly`;
  }

  document.cookie = cookieString;
}
