import { createWebHistory, createRouter } from "vue-router";
import LoginView from "./pages/auth/Login.vue";
import AdminDashboard from "./pages/admin/AdminDashboard.vue";
import ManagerDashboard from "./pages/manager/ManagerDashboard.vue";
import { getCookie, deleteCookie } from "./utils/susenka";
import { getRoleFromToken } from "./utils/jwt";

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/admin",
    name: "AdminDashboard",
    component: AdminDashboard,
    meta: {
      requiresAuth: true,
      roles: ["Admin"],
    },
  },
  {
    path: "/manager",
    name: "ManagerDashboard",
    component: ManagerDashboard,
    meta: {
      requiresAuth: true,
      roles: ["RestaurantManager"],
    },
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Globální beforeEach hook pro autentizaci a směrování
router.beforeEach((to, from, next) => {
  const token = getCookie('jwt');
  const userRole = getRoleFromToken(token);

  // Pokud není token a uživatel se nesnaží přistoupit na login, přesměrujeme na login
  if (!token && to.name !== "Login") {
    return next({ name: "Login" });
  }

  // Ověření, jestli cesta vyžaduje autentizaci
  if (to.meta.requiresAuth) {
    // Pokud není role nebo není platná, přesměrování na login
    if (!userRole) {
      return next({ name: "Login" });
    }

    // Ověření, jestli role odpovídá požadavkům na roli
    if (!to.meta.roles.includes(userRole)) {
      return next({ name: "Login" });
    }
  }

  // Pokud je uživatel přihlášen a pokouší se přistoupit na login, přesměrujeme na dashboard podle role
  if (token && userRole) {
    if (to.name === "Login") {
      if (userRole === 'Admin') {
        return next({ name: 'AdminDashboard' });
      } else if (userRole === 'RestaurantManager') {
        return next({ name: 'ManagerDashboard' });
      }
    }
  }

  // Pustíme přístup k požadované cestě, pokud jsou všechny podmínky splněny
  next();
});

export default router;