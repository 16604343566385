<template>
    <h1>Nastavení restaurace</h1>
    <v-divider class="mb-4"></v-divider>
    <v-card outlined>
        <v-card-title>
            <v-icon icon="mdi-store"></v-icon>
            <span class="ml-2">Změna informací o restauraci</span>
        </v-card-title>
        <v-card-text>
            <v-form ref="restaurantForm" @submit.prevent="saveRestaurant">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="formData.name" label="Název restaurace" outlined required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="formData.address.street" label="Adresa" outlined required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="formData.phone" label="Telefon" outlined required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="formData.email" label="E-mail" outlined required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="formData.description" label="Popis restaurace" outlined></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-combobox v-model="formData.cuisineTypes" label="Typy kuchyně" multiple outlined
                            :items="cuisineOptions"></v-combobox>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="formData.minOrderPrice" label="Minimální cena objednávky" type="number"
                            outlined required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-btn color="primary" type="submit" :loading="loading" block>
                            Uložit
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
    <v-divider class="mb-4"></v-divider>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import { useManager } from "../../composables/useManager";
import Swal from "sweetalert2";

const { createRestaurant, editRestaurant, getRestaurant } = useManager();

const formData = reactive({
    name: "",
    address: { street: "" },
    phone: "",
    email: "",
    description: "",
    cuisineTypes: [],
    minOrderPrice: null,
});

// Tady to je diskutabilní
const cuisineOptions = [
    "Česká kuchyně",
    "Italská kuchyně",
    "Asijská kuchyně",
    "Americká kuchyně",
    "Vegetariánská",
    "Vegan",
];

const loading = ref(false);
const restaurantExists = ref(false);

const loadRestaurantData = async () => {
    loading.value = true;
    try {
        const res = await getRestaurant();
        if (res.status === 200) {
            restaurantExists.value = true;
            const data = res.data;
            formData.name = data.name;
            formData.address.street = data.address.street;
            formData.phone = data.phone;
            formData.email = data.email;
            formData.description = data.description;
            formData.cuisineTypes = data.cuisineTypes || [];
            formData.minOrderPrice = data.minOrderPrice;
        }
    } catch (error) {
        if (error.response?.status === 404) {
            // Restaurace neexistuje
            restaurantExists.value = false;
        } else {
            Swal.fire("Chyba", "Nastala chyba při načítání dat.", "error");
        }
    } finally {
        loading.value = false;
    }
};

const saveRestaurant = async () => {
    loading.value = true;
    try {
        const action = restaurantExists.value
            ? editRestaurant(formData.id, formData)
            : createRestaurant(formData);
        await action;
        Swal.fire("Úspěch", "Změny byly úspěšně uloženy!", "success");
    } catch (error) {
        Swal.fire("Chyba", "Nastala chyba při ukládání změn.", "error");
        console.error(error);
    } finally {
        loading.value = false;
    }
};

onMounted(loadRestaurantData);
</script>

<style scoped></style>
