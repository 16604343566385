import { createApp } from "vue"
import "./style.scss"
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import App from "./App.vue"
import { router } from "./router"
import { cs } from "vuetify/locale"
import VueSweetalert2 from "vue-sweetalert2"
import "sweetalert2/dist/sweetalert2.min.css"

const vuetify = createVuetify({
    components,
    directives,
    locale: {
        locale: 'cs',
        fallback: 'cs',
        messages: { cs },
    },
})

createApp(App)
    .use(router)
    .use(vuetify)
    .use(VueSweetalert2)
    .mount("#app")
