<template>
    <v-dialog v-model="showDialog" max-width="500px">
        <v-card>
            <v-card-title>
                <span class="text-h6">Uživatelský profil</span>
            </v-card-title>
            <v-card-text>
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Jméno</v-list-item-title>
                            <v-list-item-subtitle>{{ user.firstName + " " + user.lastName }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Role v systému</v-list-item-title>
                            <v-list-item-subtitle>{{ user.role }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-title>
                <span class="text-h6">Změna hesla</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid">
                    <v-text-field v-model="passwords.currentPassword" label="Aktuální heslo" type="password" outlined
                        required></v-text-field>
                    <v-text-field v-model="passwords.newPassword" label="Nové heslo" type="password" outlined
                        required></v-text-field>
                    <v-text-field v-model="passwords.confirmPassword" label="Potvrzení nového hesla" type="password"
                        outlined required></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="showDialog = false">Zpět</v-btn>
                <v-btn color="success" text @click="onSubmit">Uložit</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useAuth } from "../../composables/useAuth";
import Swal from "sweetalert2";

const { changePassword, getMyData } = useAuth();

const showDialog = defineModel();
const valid = ref(false);

const passwords = ref({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
});

const user = ref({});
onMounted(() => {
    getMyData().then((data) => {
        user.value = data;
    });
});

const onSubmit = async () => {
    if (passwords.value.newPassword !== passwords.value.confirmPassword) {
        Swal.fire("Chyba", "Zadaná hesla se neshodují.", "error");
        return;
    }

    try {
        await changePassword({
            oldPassword: passwords.value.currentPassword,
            newPassword: passwords.value.newPassword,
        });
        Swal.fire("Heslo změněno", "Vaše heslo bylo úspěšně změněno.", "success");
        showDialog.value = false;
    } catch (error) {
        Swal.fire("Chyba", "Nepodařilo se změnit vaše heslo.", "error");
    }

    passwords.value.currentPassword = "";
    passwords.value.newPassword = "";
    passwords.value.confirmPassword = "";
    
    // close dialog
    showDialog.value = false;

};
</script>

<style scoped></style>