<template>
    <v-app id="inspire">
        <v-app-bar class="px-3" density="compact" flat>
            <v-tabs v-model="selectedTab" color="grey-darken-2" centered>
                <v-tab v-for="link in links" :key="link" :text="link"></v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
            <v-btn @click="showProfile = true">
                <v-icon>mdi-account-circle</v-icon>
                <span class="ml-2">Profil</span>
            </v-btn>
            <v-btn @click="onLogout" color="error">Odhlásit</v-btn>
        </v-app-bar>

        <v-main class="px-3">
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <div v-if="selectedTab === 0">
                            <DashboardTab />
                        </div>
                        <div v-if="selectedTab === 1">
                            <ManageRestaurantsTab />
                        </div>
                        <div v-if="selectedTab === 2">
                            <ManageCouriersTab />
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
    <Profile v-model="showProfile" />
</template>

<script setup>
import { ref } from "vue"
import { useRouter } from "vue-router";
import { useAuth } from "../../composables/useAuth";
import DashboardTab from "../../components/admin/DashboardTab.vue";
import ManageCouriersTab from "../../components/admin/ManageCouriersTab.vue";
import ManageRestaurantsTab from "../../components/admin/ManageRestaurantsTab.vue";
import Profile from "../../components/common/Profile.vue"

const router = useRouter();
const { logout } = useAuth();

const onLogout = async () => {
    await logout();
    router.push({ name: "Login" });
}

const links = [
    "Dashboard",
    "Správa restaurací",
    "Správa kurýrů",
]

const selectedTab = ref(0); // Initial tab index
const showProfile = ref(false);
</script>

<style scoped></style>