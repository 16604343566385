import { useApi } from "./useApi";

const api = useApi();

export function useAdmin() {
  return {
    // Vytvoření nového účtu restaurace
    createRestaurantAccount: (managerData) =>
      api.post('/admin/restaurant-account', managerData),

    // Načtení prodejů pro specifickou restauraci v rozsahu dat
    getRestaurantSales: (restaurantId, from, to) =>
      api.get(`/admin/restaurant-sales/${restaurantId}/${from}/${to}`),

    // Načtení prodejů za poslední měsíc
    getRestaurantSalesLastMonth: (restaurantId) =>
      api.get(`/admin/restaurant-sales/${restaurantId}/last-month`),

    // Přidání nového kurýra
    addCourierToSystem: (courierData) =>
      api.post('/admin/courier-account', courierData),

    // Načtení seznamu všech kurýrů
    getCouriers: () =>
      api.get('/admin/couriers'),

    // Získání detailu kurýra
    getCourierDetails: (email) =>
      api.get(`/admin/courier/${email}`),

    // Deaktivace kurýra
    toggleCourierActivity: (email) =>
      api.put(`/admin/courier/${email}/toggle-active`),

    // Overview
    getOverview: () =>
      api.get('/admin/overview'),
  };
}
