<template>
    <div>
        <h1>Správa kurýrů</h1>
        <v-divider class="mb-4"></v-divider>
        <!-- Formulář pro přidání kurýra -->
        <v-card class="mb-4" outlined>
            <v-card-title>
                <v-icon icon="mdi-car"></v-icon>
                <span class="ml-2">Přidání nového kurýra</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="formValid">
                    <v-text-field label="Jméno kurýra" v-model="courierName" :rules="[rules.required]" required />
                    <v-text-field label="Email kurýra" v-model="courierEmail" :rules="[rules.required, rules.email]"
                        required />
                    <v-text-field label="Telefon kurýra" v-model="courierPhone" :rules="[rules.required]" required />
                    <v-btn :loading="isAddingCourier" :disabled="!formValid" @click="addCourier" color="primary">
                        Přidat kurýra
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card>

        <!-- Zobrazení seznamu kurýrů -->
        <v-card outlined>
            <v-card-title>Seznam kurýrů</v-card-title>
            <v-card-text>
                <v-list v-if="couriers.length">
                    <v-list-item v-for="courier in couriers" :key="courier.courierEmail">
                        <v-list-item-content>
                            <v-list-item-title>{{ courier.courierName }}</v-list-item-title>
                            <v-list-item-subtitle>{{ courier.courierEmail }}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{ courier.courierPhone }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn :loading="courier.isLoading" @click="toggleCourierActivityFunction(courier)"
                                :color="courier.active ? 'error' : 'success'">
                                {{ courier.active ? "Deaktivovat" : "Aktivovat" }}
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <div v-else>
                    Žádní kurýři k zobrazení.
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useAdmin } from "../../composables/useAdmin";
import Swal from "sweetalert2";

const {
    addCourierToSystem,
    getCouriers,
    toggleCourierActivity,
} = useAdmin();

// Formulářová data
const courierName = ref("");
const courierEmail = ref("");
const courierPhone = ref("");
const formValid = ref(false);
const isAddingCourier = ref(false);

// Seznam kurýrů
const couriers = ref([]);

// Pravidla pro validaci
const rules = {
    required: (v) => !!v || "Toto pole je povinné",
    email: (v) => /.+@.+\..+/.test(v) || "Zadejte platnou emailovou adresu",
};

// Funkce pro přidání kurýra
const addCourier = async () => {
    if (!formValid.value) return;

    isAddingCourier.value = true;
    try {
        const courierData = {
            courierName: courierName.value,
            courierEmail: courierEmail.value,
            courierPhone: courierPhone.value,
        };
        const response = await addCourierToSystem(courierData);

        Swal.fire("Kurýr přidán", `Kurýr byl úspěšně přidán.`, "success");

        // Přidání nového kurýra do seznamu kurýrů
        couriers.value.push({
            ...courierData,
            active: true,
            isLoading: false,
        });

        resetForm();
    } catch (error) {
        console.error(error);
        Swal.fire("Chyba", "Nepodařilo se přidat kurýra.", "error");
    } finally {
        isAddingCourier.value = false;
    }
};

// Načtení seznamu kurýrů
const fetchCouriers = async () => {
    try {
        const response = await getCouriers();
        couriers.value = response.data.map((courier) => ({
            ...courier,
            active: courier.isActive,
            isLoading: false,
        }));
    } catch (error) {
        console.error(error);
        Swal.fire("Chyba", "Nepodařilo se načíst seznam kurýrů.", "error");
    }
};

// Přepínání aktivity kurýra
const toggleCourierActivityFunction = async (courier) => {
    courier.isLoading = true; // Změna stavu načítání
    try {
        const response = await toggleCourierActivity(courier.courierEmail);
        courier.active = !courier.active; // Lokální změna stavu
        Swal.fire("Aktualizováno", "Stav kurýra byl úspěšně změněn.", "success");
    } catch (error) {
        console.error(error);
        Swal.fire("Chyba", "Nepodařilo se změnit stav kurýra.", "error");
    } finally {
        courier.isLoading = false;
    }
};

// Resetování formuláře
const resetForm = () => {
    courierName.value = "";
    courierEmail.value = "";
    courierPhone.value = "";
    formValid.value = false;
};

// Načtení dat po načtení komponenty
onMounted(() => {
    fetchCouriers();
});
</script>

<style scoped>
.mb-4 {
    margin-bottom: 16px;
}
</style>
